require("core-js/modules/es6.array.index-of");

require("core-js/modules/es6.function.name");

/*!
  * domready (c) Dustin Diaz 2014 - License MIT
  * ie10 fix - Mikael Kristiansson 2019
  */
!function (name, definition) {
  if (typeof module != 'undefined') module.exports = definition();else if (typeof define == 'function' && typeof define.amd == 'object') define(definition);else this[name] = definition();
}('domready', function () {
  var ie10 = false;

  if (navigator.appVersion.indexOf("MSIE 10") !== -1) {
    ie10 = true;
  }

  var fns = [],
      _listener,
      doc = typeof document === 'object' && document,
      hack = ie10 ? doc.documentElement.doScroll() : doc.documentElement.doScroll,
      domContentLoaded = 'DOMContentLoaded',
      loaded = doc && (hack ? /^loaded|^c/ : /^loaded|^i|^c/).test(doc.readyState);

  if (!loaded && doc) doc.addEventListener(domContentLoaded, _listener = function listener() {
    doc.removeEventListener(domContentLoaded, _listener);
    loaded = 1;

    while (_listener = fns.shift()) {
      _listener();
    }
  });
  return function (fn) {
    loaded ? setTimeout(fn, 0) : fns.push(fn);
  };
});